// THIS FILE IS GENERATED BY 'npm run set-env-variables'

export const environment = {
  production: true,

  // Configuration/Feature-switches:
  envName: '',
  useServiceWorker: true,
  useOffers: true,
  useQandAs: true,
  useQandASearch: true,
  useRegionPerLocale: true,
  useFeedbackPrompt: false,

  // Configuration of content
  localeLanguage: 'ua',
  localeDir: 'ltr',
  localeAlternatives: '',
  localeAlternativesExplanation: '',
  appName: 'Український Червоний Хрест',
  appLogoUrl: 'https://help.redcross.org.ua/files/logo.png',
  mainPageHeader: '',
  mainPageIntroduction: '\n \n',
  mainPageNotification: '',
  pageAbout: '\n',
  pagePrivacy: '\n',
  mainFooter: '**Helpful Information**  \n \n',
  errorHeader: 'Data Unavailable',
  errorMessage: 'Reach out to us at: ',
  errorContactUrl: 'https://redcross.org.ua/en/contacts/',
  errorRetry: 'Try again?',

  // Regions:
  regionConfig: '',
  regions: 'ua, en,',
  regionsLabels: 'Українська, English,',
  regionsSheetIds: '14T-FTHkq14MdzRt2CW1cvmXeg7pkDvEOHg2IHrx-8o0, 1shxWY1jLUjpPCCXXdUbm59yp5fCh1qJpGcK5k-HStso,',

  // Third-party tokens:
  // Google Sheets API:
  google_sheets_api_key: 'AIzaSyDUQ25UIrVzSp2Lz033TK-NT_0p-NUU5S8',
  google_sheets_api_url: 'https://sheets.googleapis.com/v4/spreadsheets',

  // Application Insights
  appInsightsConnectionString: '',
};
